import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Session from '@rushplay/session'
import * as ApiClient from '@rushplay/api-client'

/**
 * TODO: Disable fetch if raffle is disabled
 * through env.
 */

/**
 * @name useFetchRaffleCampaigns
 * 
 * This hook fetches raffle campaign information and provides details about active, upcoming, 
 * and past campaigns, including ticket counts, campaign statuses, rewards, and winner details.
 * 
 @property {Object|null} data - The data fetched from the raffle campaigns API. Initially `null`.
 *   Example:
 *   ```json
 *  {
  "tickets": 0,
  "campaigns": [
    {
      "key": "raffle-2024-12-w1",
      "status": "running",
      "winnerId": null,
      "isWinner": false,
      "activeFrom": "2024-11-19T17:37:00+01:00",
      "activeTo": "2024-12-10T00:00:00+01:00",
      "rewards": [
        {
          "amount": 30,
          "kind": "RaffleTickets",
          "deposit_amount_from_cents": 5000,
          "deposit_amount_to_cents": 9999
        },
        {
          "amount": 50,
          "kind": "RaffleTickets",
          "deposit_amount_from_cents": 10000,
          "deposit_amount_to_cents": 14999
        },
        {
          "amount": 100,
          "kind": "RaffleTickets",
          "deposit_amount_from_cents": 15000,
          "deposit_amount_to_cents": 19999
        },
        {
          "amount": 150,
          "kind": "RaffleTickets",
          "deposit_amount_from_cents": 20000,
          "deposit_amount_to_cents": 9999999999
        }
      ]
    },
    {
      "key": "raffle-2024-12-w2",
      "status": "future",
      "winnerId": null,
      "isWinner": false,
      "activeFrom": "2024-12-10T00:00:00+01:00",
      "activeTo": "2024-12-17T00:00:00+01:00",
      "rewards": [
        ...
      ]
    },
    {
      "key": "raffle-2024-12-w3",
      "status": "future",
      "winnerId": null,
      "isWinner": false,
      "activeFrom": "2024-12-17T00:00:00+01:00",
      "activeTo": "2024-12-24T00:00:00+01:00",
      "rewards": [
        {
          "amount": 30,
          "kind": "RaffleTickets",
          "deposit_amount_from_cents": 5000,
          "deposit_amount_to_cents": 9999
        },
        {
          "amount": 50,
          "kind": "RaffleTickets",
          "deposit_amount_from_cents": 10000,
          "deposit_amount_to_cents": 14999
        },
        {
          "amount": 100,
          "kind": "RaffleTickets",
          "deposit_amount_from_cents": 15000,
          "deposit_amount_to_cents": 19999
        },
        {
          "amount": 150,
          "kind": "RaffleTickets",
          "deposit_amount_from_cents": 20000,
          "deposit_amount_to_cents": 9999999999
        }
      ]
    },
 ...
  ]
}
 *   ```
 * @property {Error|null} error - Any error that occurred during the fetch operation. Initially `null`.
 * @property {boolean} fetching - Indicates whether the data is currently being fetched. Initially `true`.
 * 
 * @example
 * 
 * function RaffleComponent() {
 *   const { data, error, fetching } = useFetchRaffleCampaigns();
 *
 *   if (fetching) return <div>Loading...</div>;
 *   if (error) return <div>Error: {error.message}</div>;
 * 
 *   return (
 *     <div>
 *       <h1>Raffle Campaigns</h1>
 *       <p>Total Tickets: {data?.tickets}</p>
 *       {data?.campaigns.map((campaign) => (
 *         <div key={campaign.key}>
 *           <h2>{campaign.key}</h2>
 *           <p>Status: {campaign.status}</p>
 *           <p>Active From: {campaign.activeFrom}</p>
 *           <p>Active To: {campaign.activeTo}</p>
 *           <p>Winner ID: {campaign.winnerId ?? 'TBD'}</p>
 *         </div>
 *       ))}
 *     </div>
 *   );
 * }
 */

// Create a context to store the raffle campaign data
const RaffleCampaignsContext = React.createContext()

/**
 * @name useRaffleCampaigns
 *
 * A custom hook to provide access to the raffle campaigns context.
 *
 * @returns {Object} - The context value containing data, error, fetching, and invalidate function.
 */
export function useRaffleCampaigns() {
  const context = React.useContext(RaffleCampaignsContext)
  if (!context) {
    throw new Error(
      'useRaffleCampaigns must be used within a RaffleCampaignsProvider'
    )
  }
  return context
}

/**
 * @name RaffleCampaignsProvider
 *
 * A context provider component to manage and share raffle campaigns data across the application.
 *
 * @param {React.PropsWithChildren} props - The children components to render within the provider.
 */
export function RaffleCampaignsProvider({ children }) {
  const [data, setData] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [fetching, setFetching] = React.useState(true)

  const apiUrl = ApiClient.getApiUrl(window.location.hostname)

  const sessionToken = ReactRedux.useSelector((state) =>
    Session.getSessionToken(state.session)
  )

  /**
   * @name handleFetch
   * Fetches raffle campaigns data and updates the context state.
   */
  async function handleFetch() {
    if (apiUrl) {
      try {
        setError(null) // Reset error

        const response = await fetch(`${apiUrl}/ctool/raffles`, {
          headers: {
            Accept: 'application/vnd.casinosaga.v1',
            Authorization: sessionToken,
          },
        }).then((res) => res.json())

        setData(response)
        setFetching(false)

        return response
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Could not fetch campaigns', err)
        setError(err)
        setFetching(false)
      }
    }
  }

  React.useEffect(() => {
    handleFetch()
  }, [sessionToken])

  return (
    <RaffleCampaignsContext.Provider
      value={{ data, error, fetching, invalidate: handleFetch }}
    >
      {children}
    </RaffleCampaignsContext.Provider>
  )
}

RaffleCampaignsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

// Define the shape of a single reward
const rewardPropType = PropTypes.shape({
  amount: PropTypes.number.isRequired,
  kind: PropTypes.string.isRequired,
  deposit_amount_from_cents: PropTypes.number.isRequired,
  deposit_amount_to_cents: PropTypes.number.isRequired,
})

// Define the shape of a single campaign
const campaignPropType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['running', 'future', 'winner_selected', 'scheduled'])
    .isRequired,
  winnerId: PropTypes.string,
  isWinner: PropTypes.bool,
  activeFrom: PropTypes.string.isRequired,
  activeTo: PropTypes.string.isRequired,
  rewards: PropTypes.arrayOf(rewardPropType).isRequired,
})

// Define the main shape of the data object
const dataPropType = PropTypes.shape({
  tickets: PropTypes.number.isRequired,
  campaigns: PropTypes.arrayOf(campaignPropType).isRequired,
})

// Define the PropTypes for the context value
const raffleCampaignsContextPropTypes = PropTypes.shape({
  data: PropTypes.oneOfType([dataPropType, PropTypes.null]),
  error: PropTypes.oneOfType([PropTypes.instanceOf(Error), PropTypes.null]),
  fetching: PropTypes.bool.isRequired,
  invalidate: PropTypes.func.isRequired,
})

RaffleCampaignsContext.propTypes = raffleCampaignsContextPropTypes
