import * as R from 'ramda'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { locationShape } from 'react-router/lib/PropTypes'
import { setItem } from 'redux-effects-localstorage'
import { withRouter } from 'react-router'

import * as analytics from '@rushplay/analytics'
import * as websockets from '@rushplay/websockets'
import styled from '@emotion/styled'
import { fetchAllActiveCampaigns } from '@rushplay/api-client'
import {
  getCountryCode,
  getSessionToken,
  getUsername,
  isSessionActive,
  updateBalance,
} from '@rushplay/session'
import { withTranslate } from '@rushplay/i18n'

import getPlayerCurrency from '../util/get-player-currency'
import triggers from '../store/triggers/'
import { CssVariables } from '../css-variables'
import { FastTrack } from '../fast-track'
import { GoogleDataLayerManager } from '../google-data-layer-manager'
import { GoogleTagManager } from '../gtm'
import { Metadata } from '../metadata'
import { SupportChat } from '../live-chat'
import { clearPopups } from '../common/popup/popups'
import {
  getConfig,
  getNetentConfig,
  getPusherConfig,
  getPusherKey,
  isEmbeddingEnabled,
  isGdprJurisdiction,
} from '../store/app'

import CookieBanner from './cookie-banner'
import NativeAppContainer from './native-app/native-app-container'
import { AffiliateBar } from './affiliate-bar'
import { Footer } from './footer'
import { LockCheck } from './lock-check'
import { Notifications } from './notifications'
import { RaffleBanner } from './raffle-banner'
import { TopBar } from './top-bar'

const AppWrapper = styled.div`
  min-height: var(--window-inner-height, 100vh);
  display: flex;
  flex-direction: column;
`

const AppBody = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
`

class AppContainer extends Component {
  constructor(props) {
    super(props)
    this.renderHelmetComponent = this.renderHelmetComponent.bind(this)
  }

  getChildContext() {
    return { locale: this.props.language }
  }

  componentWillMount() {
    if (!this.props.authenticated) {
      this.props.onCurrencyInit(this.props.playerCurrency)
    }
  }

  componentDidMount() {
    this.props.initSocket(this.props.pusherConfig.key, {
      cluster: this.props.pusherConfig.cluster,
      authEndpoint: this.props.pusherConfig.authEndpoint,
      encrypted: this.props.pusherConfig.encrypted,
      auth: {
        params: {
          username: this.props.username,
          sessionToken: this.props.sessionToken,
        },
      },
    })
  }

  componentDidUpdate(prevProps) {
    window.prerenderReady = true

    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
      this.props.updateAnalytics()
    }

    if (
      prevProps.pusherConfig.key !== this.props.pusherConfig.key ||
      prevProps.sessionToken !== this.props.sessionToken
    ) {
      this.props.initSocket(this.props.pusherConfig.key, {
        cluster: this.props.pusherConfig.cluster,
        authEndpoint: this.props.pusherConfig.authEndpoint,
        encrypted: this.props.pusherConfig.encrypted,
        auth: {
          params: {
            username: this.props.username,
            sessionToken: this.props.sessionToken,
          },
        },
      })
    }
  }

  renderHelmetComponent() {
    const metas =
      this.props.brand == 'casitabi'
        ? [
            {
              content:
                'Link: <https://www.casitabi.com/>; rel="alternate"; hreflang="ja"',
              name: 'prerender-header',
            },
          ]
        : [
            {
              content:
                'Link: <https://www.casinoheroes.com/>; rel="alternate"; hreflang="x-default", <https://www.casinoheroes.com/fi/>; rel="alternate"; hreflang="fi", <https://www.casinoheroes.com/sv/>; rel="alternate"; hreflang="sv", <https://www.casinoheroes.com/no/>; rel="alternate"; hreflang="no", <https://www.casinoheroes.com/de/>; rel="alternate"; hreflang="de", <https://www.casinoheroes.com/uk/>; rel="alternate"; hreflang="en-gb", <https://www.casinoheroes.com/en/>; rel="alternate"; hreflang="en"',
              name: 'prerender-header',
            },
          ]

    const hrefLangLocales = [
      { path: '', key: 'x-default' },
      { path: 'de', key: 'de' },
      { path: 'en', key: 'en' },
      { path: 'fi', key: 'fi' },
      { path: 'ja', key: 'ja' },
      { path: 'no', key: 'no' },
      { path: 'sv', key: 'sv' },
      { path: 'uk', key: 'en-GB' },
    ]

    const htmlLang = R.pipe(
      R.filter(R.pathEq(['path'], this.props.language)),
      R.head,
      R.prop('key')
    )(hrefLangLocales)

    const canonical = [
      {
        href: `https://www.${this.props.brand}.com${this.props.location.pathname}`,
        rel: 'canonical',
      },
    ]

    const hrefLangs = hrefLangLocales.map((locale) => {
      return {
        href: `https://www.${this.props.brand}.com/${locale.path}`,
        rel: 'alternate',
        hrefLang: locale.key,
      }
    })

    const linkTags =
      this.props.brand == 'casitabi'
        ? R.concat(
            canonical,
            R.filter(
              (obj) =>
                R.propEq('hrefLang', 'ja', obj) ||
                R.propEq('hrefLang', 'x-default', obj),
              hrefLangs
            )
          )
        : R.concat(
            canonical,
            R.dropWhile(R.propEq('hrefLang', 'ja'), hrefLangs)
          )

    return (
      <Helmet meta={metas} link={linkTags} htmlAttributes={{ lang: htmlLang }}>
        {this.props.netentConfig && (
          <script
            src={`${this.props.netentConfig.staticUrl}gameinclusion/library/gameinclusion.js`}
          />
        )}
      </Helmet>
    )
  }

  render() {
    if (this.props.embedding || window.top !== window) {
      return (
        <React.Fragment>
          <Notifications />
          <AppWrapper embedding>
            <TopBar />
            {this.props.children}
          </AppWrapper>
          <NativeAppContainer />
          <CssVariables />
        </React.Fragment>
      )
    }

    return (
      <div>
        <AppWrapper>
          {this.renderHelmetComponent()}
          <LockCheck />
          <FastTrack />
          <Metadata />

          <RaffleBanner location={this.props.location} />

          <GoogleTagManager />
          <TopBar />
          <AffiliateBar />
          <AppBody>
            <Notifications />
            <GoogleDataLayerManager />
            {this.props.children}
          </AppBody>
        </AppWrapper>
        <Footer />
        <NativeAppContainer />
        <SupportChat />
        {this.props.displayGdprContent && <CookieBanner />}
        <CssVariables />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    authenticated: isSessionActive(state.session),
    brand: getConfig(state.app).brand,
    cdnHost: getConfig(state.app).cdnHost,
    cdnPrefix: getConfig(state.app).cdnPrefix,
    countryCode: getCountryCode(state.session),
    displayGdprContent: isGdprJurisdiction(state),
    embedding: isEmbeddingEnabled(state.app),
    language: state.app && state.app.language,
    netentConfig: getNetentConfig(state.app),
    onboarding: triggers.selectors.getByEvent('onboarding', state.triggers),
    playerCurrency: getPlayerCurrency(state),
    pusherAppKey: getPusherKey(state.app),
    pusherConfig: getPusherConfig(state.app),
    sessionToken: getSessionToken(state.session),
    username: getUsername(state.session),
  }
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      clearPopups,
      setItem,
      initSocket: websockets.init,
      onCurrencyInit: (currency) =>
        updateBalance({
          account: { currency },
          timestamp: 1,
        }),
      onFetchAllActiveCampaigns: () => fetchAllActiveCampaigns({ version: 1 }),
      updateAnalytics: () =>
        analytics.pageview({
          path: R.path(['pathname'], props.location),
          /** TODO: Find a more reliable way of reading the title.
           * Currently it will pass the event before the title of the app has actually
           * updated, resulting in us sending the wrong title for the event
           **/
          title: document.title,
        }),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslate(AppContainer)))

AppContainer.childContextTypes = { locale: PropTypes.string }

AppContainer.defaultProps = {
  netentConfig: {},
}

AppContainer.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  brand: PropTypes.string.isRequired,
  children: PropTypes.element,
  displayGdprContent: PropTypes.bool,
  embedding: PropTypes.bool,
  initSocket: PropTypes.func,
  language: PropTypes.string.isRequired,
  location: locationShape,
  netentConfig: PropTypes.object,
  params: PropTypes.shape({ language: PropTypes.string }),
  playerCurrency: PropTypes.string,
  pusherConfig: PropTypes.object,
  sessionToken: PropTypes.string,
  updateAnalytics: PropTypes.func.isRequired,
  username: PropTypes.string,
  // TODO: fix @rushplay/i18n to handle currency
  onCurrencyInit: PropTypes.func.isRequired,
}
