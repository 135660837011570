import PropTypes from 'prop-types'
import React from 'react'
import reset from 'styled-reset'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'emotion-theming'

import { Global, css } from '@emotion/core'
import { TranslationProvider } from '@rushplay/i18n'

import theme from '../theme'
import { GraphqlProvider } from '../graphql-provider'
import {
  baseFontSizeLarge,
  baseFontSizeSmall,
  textFont,
} from '../constants/typography'
import { gradientBlueDown } from '../constants/colors'

import Router, { createRoutes } from './router'
import { AppPreloader } from './app-preloader'
import { RaffleCampaignsProvider } from './raffle-page/use-fetch-raffle-campaigns'

function GlobalCss() {
  return (
    <Global
      styles={css`
        ${reset};

        html {
          background-image: ${gradientBlueDown};
          font-family: ${textFont};
          font-size: ${baseFontSizeLarge};
          font-weight: 400;
          color: white;
          min-height: 100%;

          a {
            text-decoration: inherit;
            color: inherit;
          }
        }

        ::-webkit-calendar-picker-indicator {
          filter: invert(1);
        }

        @media screen and (max-width: 768px) {
          html {
            font-size: ${baseFontSizeSmall};
          }
        }
      `}
    />
  )
}

export default function Root(props) {
  return (
    <HelmetProvider>
      <Provider store={props.store}>
        <GraphqlProvider>
          <ThemeProvider theme={theme}>
            <GlobalCss />
            <AppPreloader>
              <TranslationProvider>
                <RaffleCampaignsProvider>
                  <Router
                    history={props.history}
                    routes={createRoutes(props.store)}
                    // workaround for hot reloading to work when having async routes in application:
                    // https://github.com/gaearon/react-hot-loader/issues/288#issuecomment-321991545
                    // this workaround will not be needed after switching to react-router v4.
                    key={module.hot && new Date()}
                  />
                </RaffleCampaignsProvider>
              </TranslationProvider>
            </AppPreloader>
          </ThemeProvider>
        </GraphqlProvider>
      </Provider>
    </HelmetProvider>
  )
}

Root.propTypes = {
  history: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
}
