import * as R from 'ramda'
import Loadable from 'react-loadable'
import PropTypes from 'prop-types'
import React from 'react'
import {
  IndexRedirect,
  IndexRoute,
  Redirect,
  Route,
  Router as ReactRouter,
  browserHistory,
} from 'react-router'

import * as locks from '@rushplay/compliance/locks'
import * as supportChat from '@rushplay/support-chat'
import {
  getConsent,
  getEmail,
  isPhoneVerificationRequired,
  isSessionActive,
} from '@rushplay/session'

import FaqContainer from '../faq/faq-container'
import GamblingHistoryContainer from '../gambling-history/gambling-history-container'
import GamstopInfo from '../limits/self-exclusion-success'
import HowToPlayDetailContainer from '../how-to-play/how-to-play-detail-container.js'
import HowToPlayHomeContainer from '../how-to-play/how-to-play-home-container.js'
import InventoryContainer from '../inventory'
import Limits from '../limits/limits'
import LiveCasinoContainer from '../live-casino'
import LogInDetails from '../sign-up/log-in-details'
import PartnerContainer from '../partner/partner-container'
import PayNPlayTransaction from '../wallet/pay-n-play-transaction'
import PlaySmartContainer from '../play-smart/play-smart-container'
import PrivacyPolicyUpdatesContainer from '../privacy-policy-updates'
import PromotionsContainer from '../promotions'
import PromotionsDetailContainer from '../promotions/promotions-detail-container'
import RealityCheckContainer from '../limits/reality-check-container'
import RubyStoreContainer from '../ruby-store'
import SelfExclusionContainer from '../limits/self-exclusion-container'
import TimeOutContainer from '../limits/time-out-container'
import SignupContainer, { PhoneVerification } from '../sign-up'
import bossFight from '../boss-fight'
import developmentOptions from '../development-options'
import triggers from '../store/triggers/'
import withoutLanguage from '../util/path-without-language'
import {
  BlitzGameContainer,
  CasinoHomeContainer,
  CategoryContainer,
  GameContainer,
  GameIframeContainer,
  Header,
  MicrogamingGameContainer,
  NetEntGameContainer,
  RealityCheckTimer,
} from '../casino'
import { LoginTimeLimitsContainer } from '../limits/login-time-limits-container'
import { MoneyLimits } from '../limits/money-limits'
import { PageSpinner } from '../common/spinner'

import AppContainer from './app-container'
import CampaignOverlayContainer from './region/campaign-overlay-container'
import CreateNewPasswordConfirmation from './forgot-password/create-new-password-confirmation'
import CreateNewPasswordContainer from './forgot-password/create-new-password-container'
import EditAvatar from './user-dashboard/edit-avatar'
import EditDetails from './user-dashboard/edit-details'
import EditPassword from './user-dashboard/edit-password'
import LandingPage from './landing-page'
import LockPage from './locks'
import LoginPage from './login-page'
import LogoutContainer from './logout-container'
import NotFound from './not-found'
import OnboardingContainer from './onboarding/onboarding-container'
import RegionContainer from './region/region-container'
import RegionLoggedOutContainer from './region/region-logged-out-container'
import RegionPopupsContainer from './region/region-popups-container'
import RegionPreloader from './region/region-preloader'
import ResetPasswordConfirmation from './forgot-password/reset-password-confirmation'
import ResetPasswordContainer from './forgot-password/reset-password-container'
import StaticPageContainer from './static/static-page-container'
import UserDashboardContainer from './user-dashboard/user-dashboard-container'
import WorldsContainer from './worlds/worlds-container'

const Wallet = Loadable({
  loader: () => import('../wallet'),
  render(loaded, props) {
    const Wallet = loaded.Wallet
    return <Wallet {...props} />
  },
  loading: PageSpinner,
})

const Deposit = Loadable({
  loader: () => import('../wallet'),
  render(loaded, props) {
    const Deposit = loaded.Deposit
    return <Deposit {...props} />
  },
  loading: PageSpinner,
})

const Withdraw = Loadable({
  loader: () => import('../wallet'),
  render(loaded, props) {
    const Withdraw = loaded.Withdraw
    return <Withdraw {...props} />
  },
  loading: PageSpinner,
})

const ReceiptSummary = Loadable({
  loader: () => import('../wallet'),
  render(loaded, props) {
    const ReceiptSummary = loaded.ReceiptSummary
    return <ReceiptSummary {...props} />
  },
  loading: PageSpinner,
})

const ReceiptDetails = Loadable({
  loader: () => import('../wallet'),
  render(loaded, props) {
    const ReceiptDetails = loaded.ReceiptDetails
    return <ReceiptDetails {...props} />
  },
  loading: PageSpinner,
})

const RafflePage = Loadable({
  loader: () => import('./raffle-page'),
  loading: PageSpinner,
})

const NetDepositInformationPopup = Loadable({
  loader: () => import('../wallet'),
  render(loaded, props) {
    const NetDepositInformationPopup = loaded.NetDepositInformationPopup
    return <NetDepositInformationPopup {...props} />
  },
  loading: PageSpinner,
})

// const Sportsbook = Loadable({
//   loader: () => import('../sportsbook'),
//   render(loaded, props) {
//     const Sportsbook = loaded.Sportsbook
//     return <Sportsbook {...props} />
//   },
//   loading: PageSpinner,
// })

// TODO: Remove this whenever Betby is done testing and we move back into Gamer integration..
const Sportsbook = Loadable({
  loader: () => import('../sportsbook-direct'),
  render(loaded, props) {
    const Sportsbook = loaded.SportsbookDirect
    return <Sportsbook {...props} />
  },
  loading: PageSpinner,
})

/**
 * Check if given path represents a page that can be displayed to locked player.
 * @param {string} currentPath
 * @returns {boolean}
 */
function isValidLockPage(currentPath) {
  return R.any(
    (path) => currentPath.match(path),
    [
      '/about-us',
      '/locks',
      '/logout',
      '/phone-verification',
      '/privacy-policy',
      '/privacy-policy-updates',
      '/register',
      '/terms-conditions',
      '/terms-conditions-sport',
      '/bonus-terms',
      '/responsible-gaming',
    ]
  )
}

function createHandleSupportChatLink(store) {
  return (args) => {
    if (typeof args === 'undefined') {
      // do nothing
      return
    }

    const newPath = withoutLanguage(args.nextState.location.pathname)
    const prevPath = args.prevState.location.pathname

    if (newPath === '/support-chat') {
      store.dispatch(supportChat.setTabVisibility(true))
      args.replace(prevPath)
      return
    }

    return args
  }
}

function createRedirectToSignupType(store) {
  return (args) => {
    if (typeof args === 'undefined') {
      // do nothing
      return
    }

    const path = '/register/signup-type'
    const state = store.getState()
    const language = state.app.language ? `/${state.app.language}` : ''
    const countryCode = state.lookup.countryCode

    const currentPath = withoutLanguage(args.nextState.location.pathname)

    if (
      !isSessionActive(state.session) &&
      currentPath !== path &&
      countryCode === 'FI'
    ) {
      args.replace(`${language}${path}`)
      return
    }

    return args
  }
}

function createRedirectToPath(store, path) {
  return (args) => {
    if (typeof args === 'undefined') {
      // do nothing
      return
    }
    const currentPath = withoutLanguage(args.nextState.location.pathname)
    const state = store.getState()
    const language = state.app.language ? `/${state.app.language}` : ''

    if (
      isSessionActive(state.session) &&
      !currentPath.match('/register/log-in-info') &&
      !currentPath.match('/register/success')
    ) {
      args.replace(`${language}${path}`)

      // stop callback chain here
      return
    }

    // continue executing callbacks
    return args
  }
}

function createRequirePhoneVerification(store) {
  return (args) => {
    if (typeof args === 'undefined') {
      // do nothing
      return
    }
    const state = store.getState()
    const currentPath = withoutLanguage(args.nextState.location.pathname)
    const validPage = isValidLockPage(currentPath)

    if (isPhoneVerificationRequired(state.session)) {
      if (!validPage) {
        const language = state.app.language ? `/${state.app.language}` : ''
        args.replace(`${language}/phone-verification`)
      }

      // stop callback chain here
      return
    }

    // continue executing callbacks
    return args
  }
}

function createRequirePlayerConsent(store) {
  return (args) => {
    if (typeof args === 'undefined') {
      // do nothing
      return
    }
    const state = store.getState()
    const currentPath = withoutLanguage(args.nextState.location.pathname)
    const validPage = isValidLockPage(currentPath)

    if (isSessionActive(state.session) && !getConsent(state.session)) {
      if (!validPage) {
        const language = state.app.language ? `/${state.app.language}` : ''
        args.replace(`${language}/privacy-policy-updates`)
      }

      // stop callback chain here
      return
    }

    // TODO: it might not be the best place for this redirect
    if (
      isSessionActive(state.session) &&
      getConsent(state.session) &&
      currentPath.match('/privacy-policy-updates')
    ) {
      const language = state.app.language ? `/${state.app.language}` : ''
      args.replace(`${language}/`)

      // stop callback chain here
      return
    }

    // continue executing callbacks
    return args
  }
}

/*
 * Locks are conditions that require player’s attention right away and prevent
 * any application usage until desired action is taken.
 *
 * Previously locks were represented by arbitrary properties in session
 * response.
 *
 * Updated approach is to consolidate all of them in single place and simplify
 * creation and support of new locks.
 *
 * List of current locks is obtained via GET /my/locks and stored in Redux.
 * Lock types are unique and must be used as slugs for lock pages.
 */
function createRequireLockAction(store) {
  return (args) => {
    if (typeof args === 'undefined') {
      // do nothing
      return
    }
    const state = store.getState()
    const currentPath = withoutLanguage(args.nextState.location.pathname)
    const validPage = isValidLockPage(currentPath)

    const currentLocks = locks.getLocks(state.locks)

    if (currentLocks.length > 0) {
      if (!validPage) {
        const language = state.app.language ? `/${state.app.language}` : ''
        const lock = R.head(currentLocks)
        args.replace(`${language}/locks/${lock}`)
      } else if (
        currentPath.match('/locks') &&
        R.none((lock) => currentPath.match(`/locks/${lock}`), currentLocks)
      ) {
        args.replace('/')
      }

      // stop callback chain here
      return
    } else if (currentPath.match('/locks/')) {
      args.replace('/')

      // stop callback chain here
      return
    }

    // continue executing callbacks
    return args
  }
}

function createRequireOnboardingCompletion(store) {
  return (args) => {
    if (typeof args === 'undefined') {
      //do nothing
      return
    }

    const state = store.getState()
    const onboarding = triggers.selectors.getByEvent(
      'onboarding',
      state.triggers
    )

    const currentPath = withoutLanguage(args.nextState.location.pathname)

    if (
      onboarding &&
      !currentPath.match('/onboarding') &&
      !currentPath.match('/dashboard/limits') &&
      !currentPath.match('register/log-in-info')
    ) {
      const language = state.app.language ? `/${state.app.language}` : ''
      args.replace(`${language}/onboarding`)

      // stop callback chain here
      return
    }

    // continue executing callbacks
    return args
  }
}

function createRequireAuth(store) {
  return (args) => {
    if (typeof args === 'undefined') {
      return
    }

    if (!isSessionActive(store.getState())) {
      // TODO: redirect to 404
      args.replace('/')
    }

    return args
  }
}

function createRequireAdditionalDetails(store) {
  return (args) => {
    if (typeof args === 'undefined') {
      // do nothing
      return
    }

    const state = store.getState()
    const email = getEmail(state.session)
    const currentPath = withoutLanguage(args.nextState.location.pathname)
    const authenticated = isSessionActive(state)
    const validPage = isValidLockPage(currentPath)

    if (!email && authenticated) {
      if (!validPage) {
        const previousStatePath = R.pathOr(
          '',
          ['prevState', 'location', 'pathname'],
          args
        )
        const languagePath = state.app.language ? `/${state.app.language}` : ''
        const url = previousStatePath.match('/register/log-in-info')
          ? previousStatePath
          : `${languagePath}/register/log-in-info`
        args.replace(url)
      }

      // stop callback chain here
      return
    }
    // continue executing callbacks
    return args
  }
}

function handleOnEnterArguments(nextState, replace) {
  return { nextState, replace }
}

function handleOnChangeArguments(prevState, nextState, replace) {
  return { prevState, nextState, replace }
}

export function createRoutes(store) {
  const handleSupportChatLink = createHandleSupportChatLink(store)
  const redirectToAdventure = createRedirectToPath(store, '/adventure')
  const redirectToCasino = createRedirectToPath(store, '/casino')
  const redirectToSignupType = createRedirectToSignupType(store)
  const requirePlayerConsent = createRequirePlayerConsent(store)
  const requirePhoneVerification = createRequirePhoneVerification(store)
  const requireLockAction = createRequireLockAction(store)
  const requireAdditionalDetails = createRequireAdditionalDetails(store)
  const requireAuth = R.pipe(handleOnEnterArguments, createRequireAuth(store))
  const requireOnboardingCompletion = createRequireOnboardingCompletion(store)

  const state = store.getState()
  const isDevelopmentEnvironment = state.app.config.environment !== 'production'

  return (
    <Route>
      <Redirect from="/(:language)/index.html" to="/(:language)" />

      <Route
        component={AppContainer}
        path="/(:language)"
        onChange={R.pipe(
          handleOnChangeArguments,
          handleSupportChatLink,
          requirePhoneVerification,
          requireAdditionalDetails,
          requirePlayerConsent,
          requireLockAction
        )}
        onEnter={R.pipe(
          handleOnEnterArguments,
          requirePhoneVerification,
          requireAdditionalDetails,
          requirePlayerConsent,
          requireLockAction
        )}
      >
        <IndexRoute
          component={LandingPage}
          onEnter={R.pipe(
            handleOnEnterArguments,
            redirectToSignupType,
            redirectToCasino
          )}
        />

        <Route component={PartnerContainer} path="partner/:id" />

        <Route component={Sportsbook} path="sports" />
        <Route component={RafflePage} path="raffle" />

        <Route onEnter={requireAuth}>
          <Route component={Wallet} path="wallet">
            <IndexRoute component={Deposit} />
            <Route path="deposit" component={Deposit} />
            <Route path="withdraw" component={Withdraw} />
            <Route component={ReceiptSummary} path="receipts(/page/:page)">
              <Route component={NetDepositInformationPopup} path=":id" />
              <Route component={ReceiptDetails} path="receipt/:id" />
            </Route>
          </Route>
        </Route>

        <Route component={Header} path="casino">
          <IndexRoute component={CasinoHomeContainer} />
          <Route component={CategoryContainer} path=":category" />
          <Route component={RealityCheckTimer}>
            <Route
              component={(props) => (
                <GameContainer key={props.location.query.mode} {...props} />
              )}
            >
              <Route
                getComponent={(nextState, cb) => {
                  if (nextState.params.category === 'blitz') {
                    return cb(null, BlitzGameContainer)
                  }

                  // Pick provider-specific container if needed
                  switch (nextState.params.provider) {
                    case 'microgaming':
                      return cb(null, MicrogamingGameContainer)
                    case 'netent':
                      return cb(null, NetEntGameContainer)
                    default:
                      return cb(null, GameIframeContainer)
                  }
                }}
                path=":category/:provider/:gameId(/:gameKey)"
              />
            </Route>
          </Route>
        </Route>

        <Route component={PromotionsDetailContainer} path="promotion/:terms" />
        <Route component={PromotionsContainer} path="promotions/:tag" />
        <Route component={PromotionsContainer} path="promotions" />

        <Route component={LiveCasinoContainer} path="live-casino(/:category)" />

        <Route
          component={RubyStoreContainer}
          path="rubystore"
          onEnter={requireAuth}
        />
        <Route
          component={RegionPreloader}
          onEnter={R.pipe(requireAuth, requireOnboardingCompletion)}
        >
          <Route component={RegionContainer} path="adventure">
            <Route component={CampaignOverlayContainer} path="campaigns/:key" />
            <Route component={RegionPopupsContainer} path=":popupKey" />
          </Route>
        </Route>

        <Route
          component={Limits}
          path="dashboard/limits"
          onEnter={R.pipe(requireAuth)}
        >
          <IndexRedirect to="money-limits" />
          <Route
            component={MoneyLimits}
            path="money-limits"
            ignoreScrollBehavior
          />
          <Route
            component={GamstopInfo}
            path="self-exclusion-success/:license"
          />

          <Route component={RealityCheckContainer} path="reality-check" />
          <Route component={LoginTimeLimitsContainer} path="session-limits" />
          <Route component={TimeOutContainer} path="time-out" />
          <Route component={SelfExclusionContainer} path="self-exclusion" />
        </Route>

        <Redirect from="player-limits" to="money-limits" />

        <Route
          component={GamblingHistoryContainer}
          path="dashboard/history"
          onEnter={R.pipe(requireAuth)}
        />
        <Route
          component={EditPassword}
          path="dashboard/password"
          onEnter={requireAuth}
        />

        <Route
          component={EditDetails}
          path="dashboard/edit-details"
          onEnter={requireAuth}
        />

        <Route
          component={EditAvatar}
          path="dashboard/edit-avatar"
          onEnter={requireAuth}
        />

        <Route
          component={UserDashboardContainer}
          path="dashboard"
          onEnter={requireAuth}
        />

        <Route component={ResetPasswordContainer} path="reset-password" />
        <Route
          component={ResetPasswordConfirmation}
          path="reset-password-confirmation"
        />
        <Route component={CreateNewPasswordContainer} path="password/:token" />
        <Route
          component={CreateNewPasswordConfirmation}
          path="create-new-password-confirmation"
        />
        <Route
          component={PhoneVerification}
          path="phone-verification(/:step)"
          onEnter={requireAuth}
        />
        <Route
          component={PrivacyPolicyUpdatesContainer}
          path="privacy-policy-updates"
          onEnter={requireAuth}
        />
        <Route
          component={InventoryContainer}
          path="treasure-chest(/:id)(/:secretId)"
          onEnter={requireAuth}
        />
        <Route component={WorldsContainer} path="worlds(/:worldKey)" />
        {bossFight('bossfight', requireAuth, store)}
        {isDevelopmentEnvironment && developmentOptions('magic')}
        <Route
          component={OnboardingContainer}
          path="onboarding"
          onEnter={requireAuth}
        />

        <Route component={LockPage} path="locks/:lock" onEnter={requireAuth} />

        <Route component={StaticPageContainer} path="bonus-terms" />
        <Route component={StaticPageContainer} path="about-us" />
        <Route component={StaticPageContainer} path="terms-conditions" />
        <Route component={StaticPageContainer} path="terms-conditions-sport" />
        <Route component={StaticPageContainer} path="privacy-policy" />
        <Route component={FaqContainer} path="help" />
        <Redirect from="play-smart" to="responsible-gaming" />
        <Route component={PlaySmartContainer} path="responsible-gaming" />

        <Route
          component={HowToPlayDetailContainer}
          path="how-to-play/:tutorial"
        />
        <Route component={HowToPlayHomeContainer} path="how-to-play" />

        <Route
          path="register"
          onEnter={R.pipe(handleOnEnterArguments, redirectToCasino)}
        >
          <IndexRoute component={SignupContainer} />
          <Route component={PayNPlayTransaction} path="deposit" />
          <Route component={LogInDetails} path="log-in-info(/:step)" />
          <Route component={SignupContainer} path=":step" />
        </Route>

        <Route
          component={RegionLoggedOutContainer}
          path="adventure-promo"
          onEnter={R.pipe(handleOnEnterArguments, redirectToAdventure)}
        />

        <Route
          component={LogoutContainer}
          path="logout"
          onEnter={requireAuth}
        />
        <Route
          component={LoginPage}
          path="login"
          onEnter={R.pipe(handleOnEnterArguments, redirectToCasino)}
        />
        <Route component={NotFound} path="*" />
      </Route>
    </Route>
  )
}

function Router({ history, routes }) {
  return <ReactRouter history={history} routes={routes} />
}

Router.defaultProps = { history: browserHistory }

Router.propTypes = {
  // TODO: add shape
  history: PropTypes.object,
  routes: PropTypes.element.isRequired,
}

export default Router
