import { schema, normalize } from 'normalizr'
import * as R from 'ramda'
import * as http from './http'
import toQueryString from './to-query-string'

/**
 * @param {FetchConfig} config
 */
export function fetchAvatars(config) {
  return http.get('/avatars', config)
}

/**
 * Get current state of the BossFight
 * @param {FetchConfig} config
 */
export function startBossFight(config) {
  return http.post('/grid_battle', config)
}

/**
 * Get a list of Available Treasures in the BossFight
 * @param {FetchConfig} config
 */
export function fetchAvailableBossFightTreasures(config) {
  return http.get('/grid_battle/available_treasures', config)
}

/**
 * Get Boss Fight Action
 * @param {FetchConfig} config
 */
export function generateBossFightBoxAction(config) {
  return http.post('/grid_battle/box', config)
}

/**
 * Use weapon against boss
 * @param {number} id
 * @param {FetchConfig} config
 */
export function useBossFightWeapon(id, config) {
  if (id == null) {
    throw new TypeError('id is required')
  }

  if (typeof id !== 'number') {
    throw new TypeError('id must be a number')
  }

  return http.delete(`/grid_battle/weapons/${id}`, config)
}

/**
 * Get region specific bossFight assets
 * @param {string}
 * @param {FetchConfig} config
 */
export function fetchBossFightRegionAssets(regionKey, config) {
  if (regionKey == null) {
    throw new TypeError('regionKey is required')
  }

  if (typeof regionKey !== 'string') {
    throw new TypeError('regionKey must be a string')
  }

  return http.get(`/regions/${regionKey}/battle_assets`, config)
}

/**
 * @param {FetchConfig} config
 */

export function fetchAllActiveCampaigns(config) {
  return http.get('/ctool/campaigns', config)
}

/**
 * @param {FetchConfig} config
 */
export function fetchConfig(config) {
  return http.get('/config', config)
}

/**
 * Get all supported countries.
 * @param {FetchConfig} config
 */
export function fetchCountries(config) {
  return http.get('/countries/enabled', config)
}

/**
 * Get all enabled countries.
 * @param {FetchConfig} config
 */
export function fetchExtendAccountCountries(config) {
  return http.get('/countries/extended_account_enabled', config)
}

/**
 * Get current country code.
 * @param {FetchConfig} config
 */
export function fetchCountryCode(config) {
  return http.get('/country/lookup', config)
}

/**
 * @param {FetchConfig} config
 */
export function fetchDate(config) {
  return http.get('/date', config)
}

/**
 * Get deposit offers
 * @param {Object} params
 * @param {string} [params.affiliateId] affiliate id
 * @param {FetchConfig} config
 */
export function fetchDepositOffers(params, config) {
  const queryString = toQueryString({
    affiliate_id: params.affiliateId,
  })

  return http.get(`/deposit_offers${queryString}`, config)
}

/**
 * @param {number} offerPackageId
 * @param {FetchConfig} config
 */
export function claimDepositOffer(offerPackageId, config) {
  const params = { body: { deposit_offer_package_id: offerPackageId } }
  return http.post('/deposit_offers', R.merge({ params }, config))
}

/**
 * @param {FetchConfig} config
 */
export function deleteClaimedDepositOffer(config) {
  return http.delete(`/deposit_offer`, config)
}

/**
 * @param {FetchConfig} config
 */
export function fetchGameProviders(config) {
  return http.get(`/game_providers`, config)
}

/**
 * @param {FetchConfig} config
 */
export function fetchLeaderboard(config) {
  return http.get('/division_table', config)
}

/**
 * Get total deposits crossbrand
 * @param {FetchConfig} config
 */
export function fetchTotalDepositsCents(config) {
  return http.get('/my/info/total_deposit_cents', config)
}

/**
 * Get IP info.
 * @param {FetchConfig} config
 */
export function fetchIpInfo(config) {
  return http.get('/ip_lookup', config)
}

/**
 * Adds a game to the logged-in players' quick games.
 * @param {string} provider
 * @param {string} key
 * @param {FetchConfig} config
 */
export function addQuickGame(provider, key, config) {
  const params = {
    body: {
      provider,
      key,
    },
  }

  return http.post('/games/quick', R.merge({ params }, config))
}

/**
 * @param {Object} options
 * @param {number} options.id
 * @param {string} [options.type=browser] `browser` or `mobile-browser`, if no value is provided, will default to `browser`
 * @param {string} [options.elementId] - target DOM element ID
 * @param {string} [options.lobbyUrl] - used only if type is `mobile-browser`
 * @param {number} [options.realityCheckTimeRemaining] - Number of milliseconds before reality check should be displayed
 * @param {string} [options.limitsUrl] - URL to a page where player can set limits
 * @param {string} [options.betHistoryUrl] - URL to a page that shows the player's play history
 * @param {string} [options.freeGames] - used for proper redirection after using a FreeGamesVoucher
 * @param {FetchConfig} options.config
 */
export function fetchGame(options) {
  const params = R.pickAll(
    [
      'id',
      'type',
      'elementId',
      'language',
      'limitsUrl',
      'lobbyUrl',
      'betHistoryUrl',
      'realityCheckTimeRemaining',
      'config',
      'origin',
      'freeGames',
    ],
    options
  )

  const queryString = toQueryString({
    element_id: params.elementId,
    language: params.language,
    lobby_url: params.lobbyUrl,
    limits_url: params.limitsUrl,
    origin: params.origin,
    bet_history_url: params.betHistoryUrl,
    reality_check_time_remaining: params.realityCheckTimeRemaining,
    type: params.type,
    free_games: params.freeGames,
  })

  return http.get(`/games/${params.id}${queryString}`, params.config)
}

/**
 * Get launch parameters for non-game entities
 * @param {Object} params
 * @param {string} params.id
 * @param {string} params.provider
 * @param {FetchConfig} config
 */
export function fetchLaunchParameters(params, config) {
  return http.get(`/launch_parameters/${params.provider}/${params.id}`, config)
}

/**
 * @param {Object} options
 * @param {string} [options.type=browser] `browser` or `mobile-browser`, if no value is provided, will default to `browser`
 * @param {number} [options.limit] number of games to be fetched, if no value provided, limit is infinity
 * @param {number} [options.offset] number indicating how many games to skip
 * @param {FetchConfig} config
 */
export function fetchGames(options) {
  const params = R.pickAll(['type', 'limit', 'offset', 'config'], options)

  const queryString = toQueryString({
    type: params.type,
    limit: params.limit,
    offset: params.offset,
  })

  return http.get(`/games${queryString}`, params.config)
}

/**
 * Fetch game catalog index
 * @param {string} catalogId - Catalog ID
 * @param {FetchConfig} config
 */
export function fetchGameCatalog(catalogId, config) {
  return http.get(`/game_catalogs/${catalogId}`, config)
}

/**
 * Fetch game catalog categories
 * @param {Object} options
 * @param {number} options.categoryId Category ID
 * @param {'browser'|'mobile-browser'|'ios'} options.clientType Client type
 * @param {FetchConfig} config
 */
export function fetchGameCatalogCategories(options, config) {
  const params = R.pickAll(['categoryId', 'clientType'], options)

  const queryString = toQueryString({
    clientType: params.clientType,
  })

  return http.get(
    `/game_catalog_categories/${params.categoryId}${queryString}`,
    config
  )
}

/**
 * Fetch game catalog sections
 * @param {Object} options
 * @param {number} options.sectionId Section ID
 * @param {'browser'|'mobile-browser'|'ios'} options.clientType Client type
 * @param {number} [options.limit] number of games to be fetched, if no value provided, limit is infinity
 * @param {number} [options.offset] number indicating how many games to skip
 * @param {FetchConfig} config
 */
export function fetchGameCatalogSections(options, config) {
  const params = R.pickAll(
    ['clientType', 'sectionId', 'limit', 'offset'],
    options
  )

  const queryString = toQueryString({
    clientType: params.clientType,
    limit: params.limit,
    offset: params.offset,
  })

  return http.get(
    `/game_catalog_sections/${params.sectionId}${queryString}`,
    config
  )
}

/**
 * @param {object} queryParams
 * @param {boolean} queryParams.mobile - Should support touch
 * @param {number} queryParams.limit - Limit games in response
 * @param {number} queryParams.offset - Indicating how many games to skip
 * @param {Array} queryParams.tags - Return games with specific tags like "slots"
 * @param {boolean} queryParams.recent - Return only recently played gamed
 * @param {boolean} queryParams.text - Return games matched by text snippet
 * @param {FetchConfig} config
 */
export function searchGames(queryParams, config) {
  const params = { body: { q: queryParams } }
  const requestParams = R.merge({ params }, config)

  return http.post('/games/search', requestParams)
}

/**
 * Do a blitz spin
 * @param {object} options
 * @param {number} options.betAmountCents - The stake of the spin
 * @param {number} options.betLevel - The level of the spin
 * @param {string} options.betLines - Bet lines
 * @param {number} options.denomination - The denomination of the spin
 * @param {number} options.id - The id of the game to spin
 * @param {boolean} options.wantsFreeRounds - t/f if you want to play freespins or not
 * @param {string} options.spinAction - Spin action type, used for respins
 * @param {object} options.requestOptions - Free form object usually taken from BE
 * @param {FetchConfig} config
 */
export function makeBlitzSpin(options, config) {
  const params = {
    body: R.merge(
      {
        betAmountCents: options.betAmountCents,
        betLevel: options.betLevel,
        betLines: options.betLines,
        denomination: options.denomination,
        spinAction: options.spinAction,
        wantsFreeRounds: options.wantsFreeRounds,
      },
      options.requestOptions || {}
    ),
  }

  return http.post(`/games/${options.id}/blitz`, R.merge({ params }, config))
}

/**
 * Ignore next occurrences of given spin action for Blitz game, i.e. avoid
 * getting notifications for specific bonus round type
 * @param {object} options
 * @param {string} options.spinAction - Spin action type to ignore
 * @param {number} options.id - The id of the game to ignore actions on
 */
export function ignoreBlitzAction(options, config) {
  const params = {
    body: {
      ignored_action: options.spinAction,
    },
  }

  return http.post(
    `/games/${options.id}/ignored_blitz_actions`,
    R.merge({ params }, config)
  )
}

/**
 * Reset any blitz actions ignored using ignoreBlitzAction
 * @param {number} id - The id of the game to reset actions on
 */
export function resetBlitzActions(id, config) {
  return http.delete(`/games/${id}/ignored_blitz_actions`, config)
}

/**
 * Get active items that the player can use.
 * @param {FetchConfig} config
 */
export function fetchInventory(config) {
  return http.get('/inventory', config)
}

/**
 * Get secret prizes that the player can use
 * @param {FetchConfig} config
 */
export function fetchSecretPrizes(config) {
  return http.get('/secret_prizes', config)
}

/**
 * Delete secret prize when it get's claimed
 * @param {number} id
 * @param {FetchConfig} config
 */
export function deleteSecretPrize(id, config) {
  return http.delete(`/secret_prizes/${id}`, config)
}

/**
 * Mark inventory item as used.
 * @param {number} inventoryId
 * @param {FetchConfig} config
 */
export function useItem(inventoryId, config) {
  return http.put(`/inventory/${inventoryId}`, config)
}

/**
 * Reset treasure-chest count.
 * @param {FetchConfig} config
 */
export function readInventoryNotifications(config) {
  return http.put('/read_inventory_notifications', config)
}

/**
 * @param {string} language
 * @param {FetchConfig} config
 */
export function saveLanguagePreference(language, config) {
  const params = {
    body: {
      address: { language: null },
      language,
    },
  }

  const requestParams = R.merge({ params }, config)

  return http.put('/me', requestParams)
}

/**
 * Dismiss Deposit Threshold Prompt
 * @param {FetchConfig} config
 */
export function dismissDepositThresholdPrompt(config) {
  return http.post('/my/responsible_gaming_deposit_threshold_prompt', config)
}

/**
 * Dismiss Free Text Pop-Up
 * @param {number} id
 * @param {FetchConfig} config
 */
export function dismissFreeTextPopup(id, config) {
  return http.put(`/my/messages/${id}`, config)
}

/**
 * Dismiss Responsible Gaming Prompt
 * @param {FetchConfig} config
 */
export function dismissResponsibleGamingPrompt(config) {
  return http.put('/my/responsible_gaming_prompt', config)
}

/**
 * Dismiss Source of Funds
 * @param {FetchConfig} config
 */
export function dismissSourceOfFunds(config) {
  return http.post('/my/source_of_funds_request_attempts', config)
}

/**
 * Dismiss Source of Wealth
 * @param {FetchConfig} config
 */
export function dismissSourceOfWealth(config) {
  return http.post('/my/dismiss_source_of_wealth', config)
}

/**
 * Dismiss phone verification
 * @param {FetchConfig} config
 */
export function dismissPhoneVerification(config) {
  return http.put('/dismiss_phone_verification', config)
}

/**
 * Fetch a list of live casino tables
 * @param {Object} options
 * @param {string} [options.type=browser] `browser` or `mobile-browser`, if no value is provided, will default to `browser`
 * @param {Object} [options.config]
 */
export function fetchLiveCasinoTables(options = {}) {
  const queryString = toQueryString({
    type: options.type || 'browser',
  })
  return http.get(`/live_casino_tables${queryString}`, options.config)
}

/**
 * @function fetchLiveStatistics
 * @deprecated
 * @param {FetchConfig} config
 */
/* istanbul ignore next: deprecated */
export function fetchLiveStatisticsV2(config) {
  return http.get('/live_statistics', config)
}

/**
 * @param {Object} options
 * @param {number} [options.limit] Max items to be returned. BE will return 3 if not passed
 * @param {FetchConfig} config
 */
export function fetchLiveStatistics(options, config) {
  /* istanbul ignore next: dev-oriented code */
  if (arguments.length === 1) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn(
        'DEPRECATED',
        'fetchLiveStatistics V2 API will be removed in next major release'
      )
    }
    return fetchLiveStatisticsV2(options)
  }

  const queryString = toQueryString({
    limit: R.path(['limit'], options),
  })

  return http.get(`/live_statistics${queryString}`, config)
}

/* eslint-disable camelcase */
/**
 * @param {FetchConfig} config
 */
export function fetchPlayerGames(config) {
  return http.get('/my/games', config)
}

/**
 * @param {FetchConfig} config
 */
export function deleteGift(config) {
  return http.delete('/my/gift', config)
}

/**
 * @param {FetchConfig} config
 */
export function claimGift(config) {
  return http.post('/my/gift', config)
}

/**
 * @param {FetchConfig} config
 */
export function fetchPlayerInfo(config) {
  return http.get('/my/info', config)
}

/**
 * @param {FetchConfig} config
 */
export function fetchPlayerRegion(config = {}) {
  /* istanbul ignore next */
  const normalizedSuccess = res => {
    const treasuresSchema = new schema.Entity('treasures')

    const normalizedTreasures = normalize(res.value.unfoundTreasures, [
      treasuresSchema,
    ])

    const response = R.assocPath(
      ['value', 'unfoundTreasures'],
      normalizedTreasures,
      res
    )

    return config.success(response)
  }

  return http.get(
    '/my/region',
    R.when(
      R.propEq('normalize', true),
      R.assoc('success', normalizedSuccess),
      config
    )
  )
}

/**
 * @param {FetchConfig} config
 */
export function fetchPlayerLimits(config) {
  return http.get('/my/limits', config)
}

/**
 * @param {("15 minutes" | "30 minutes" | "60 minutes")} period
 * @param {FetchConfig} config
 */
export function setRealityCheckSettings(period, config) {
  const requestBody = {
    reality_check_settings: {
      period,
    },
  }
  const requestParams = R.merge({ params: { body: requestBody } }, config)

  return http.put('/my/reality_check_settings', requestParams)
}

/**
 * @param {FetchConfig} config
 */
export function removeRealityCheckSettings(config) {
  return http.delete('/my/reality_check_settings', config)
}

/**
 * Backwards compatibility alias for `setRealityCheckSettings`
 * @deprecated
 * @param {("15 minutes" | "30 minutes" | "60 minutes")} period
 * @param {FetchConfig} config
 */
export /* istanbul ignore next */ function requestPlayerRealityCheckSettings(
  period,
  config
) {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.warn('DEPRECATED', 'Renamed to `setRealityCheckSettings`')
  }

  return setRealityCheckSettings(period, config)
}

/**
 * @param {("bet"|"loss"|"deposit")} type
 * @param {("day"|"week"|"month")} period
 * @param {number} amountCents
 * @param {FetchConfig} config
 */
export function requestPlayerLimit(type, period, amountCents, config) {
  const requestBody = {
    limits: {
      type,
      period,
      value_cents: amountCents,
    },
  }

  const requestParams = R.merge({ params: { body: requestBody } }, config)

  return http.put('/my/limits', requestParams)
}

/**
 * @param {Object} limits
 * @param {("bet"|"loss"|"deposit")} limits.type
 * @param {("day"|"week"|"month")} limits.period
 * @param {number} limits.amountCents
 * @param {FetchConfig} config
 */

export function requestPlayerLimits(limits, config) {
  var requestBody = {
    limits: R.map(
      limit => ({
        type: limit.type,
        period: limit.period,
        value_cents: limit.amountCents,
      }),
      limits
    ),
  }

  const requestParams = R.merge({ params: { body: requestBody } }, config)

  return http.put('/my/limits', requestParams)
}

/**
 * @param {("bet"|"loss"|"deposit")} type
 * @param {("day"|"week"|"month")} period
 * @param {FetchConfig} config
 */
export function cancelPlayerLimit(type, period, config) {
  const requestBody = {
    limits: {
      type,
      period,
      value_cents: null,
    },
  }

  const requestParams = R.merge({ params: { body: requestBody } }, config)

  return http.put('/my/limits', requestParams)
}

/**
 * @param {FetchConfig} config
 */
export function confirmPlayerLimits(config) {
  return http.put('/my/unset_deposit_limits_popup', config)
}

// TODO: remove wrapping function with incorrect name
// along with next BREAKING CHANGE
/**
 * @param {FetchConfig} config
 */
export function confirmUnsetPlayerLimits(config) {
  return confirmPlayerLimits(config)
}

/**
 * @param {("1 day"|"7 days"|"2 weeks"|"6 weeks")} period
 * @param {string} password
 * @param {FetchConfig} config
 */
export function requestPlayerTimeOut(period, password, config) {
  const requestBody = {
    player: {
      password,
      expires_in: period,
    },
  }

  const requestParams = R.merge({ params: { body: requestBody } }, config)

  return http.post('/my/timeout', requestParams)
}

/**
 * @param {("6 months"|"1 year"|"2 years"|"3 years"|"4 years"|"5 years")} period
 * @param {string} type
 * @param {FetchConfig} config
 */
export function requestPlayerSelfExclusion(period, password, config) {
  const requestBody = {
    player: {
      password,
      expires_in: period,
    },
  }

  const requestParams = R.merge({ params: { body: requestBody } }, config)

  return http.post('/my/self_exclusion', requestParams)
}

/**
 * @param {string} key
 * @param {FetchConfig} config
 */
export function updatePlayerWorlds(key, config) {
  const requestParams = R.merge(
    { params: { body: { world_key: key } } },
    config
  )

  return http.put('/my/world', requestParams)
}

/**
 * @param {FetchConfig} config
 */
export function updateRegionProgress(config) {
  return http.put('/my/region', config)
}

/**
 * @param {number} treasureId
 * @param {FetchConfig} config
 */
export function deleteTreasure(treasureId, config) {
  return http.delete(`/my/treasures/${treasureId}`, config)
}

/**
 * @param {number} itemsPerPage
 * @param {object} params
 * @param {FetchConfig} config
 */
export function fetchGamblingHistory(itemsPerPage, params, config) {
  const requestParams = {
    per_page: itemsPerPage,
    start_date: params.startDate,
    end_date: params.endDate,
    cursor_timestamp: params.cursor && params.cursor.timestamp,
    include_totals: params.includeTotals,
    exclude_sport: params.excludeSport,
    exclude_casino: params.excludeCasino,
  }

  return http.get(
    `/my/game_transactions${toQueryString(requestParams)}`,
    config
  )
}

/**
 * @param {number} campaignId
 * @param {FetchConfig} config
 */
export function optInCampaign(campaignId, config) {
  const requestBody = { campaign_id: campaignId }
  const requestParams = R.merge({ params: { body: requestBody } }, config)

  return http.post('/my/opted_in_campaigns', requestParams)
}

/**
 * @param {number} campaignId
 * @param {FetchConfig} config
 */
export function optOutCampaign(campaignId, config) {
  const requestBody = { campaign_id: campaignId }
  const requestParams = R.merge({ params: { body: requestBody } }, config)

  return http.delete('/my/opted_in_campaigns', requestParams)
}

/**
 * @param {FetchConfig} config
 */
export function optOutAllDepositCampaigns(config) {
  const requestBody = { opt_out_from_all_deposit_campaigns: true }
  const requestParams = R.merge({ params: { body: requestBody } }, config)

  return http.delete('/my/opted_in_campaigns', requestParams)
}

/* eslint-enable camelcase */

/**
 * @param {FetchConfig} config
 */
export function fetchAllNotifications(config) {
  return http.get('/notifications', config)
}

/**
 * @param {string} kind
 * @param {FetchConfig} config
 */
export function fetchNotificationsByKind(kind, config) {
  return http.get(`/notifications?kind=${kind}`, config)
}

/**
 * @param {number} notificationId
 * @param {FetchConfig} config
 */
export function deleteNotification(notificationId, config) {
  return http.delete(`/notifications/${notificationId}`, config)
}

/**
 * @param {number} affiliateId
 * @param {string} brandKey
 * @param {string} countryCode
 * @param {FetchConfig} config
 */
export function fetchWelcomeOffers(affiliateId, brandKey, countryCode, config) {
  return http.get(
    `/offers?affiliateId=${affiliateId}&brandKey=${brandKey}&countryCode=${countryCode}`,
    config
  )
}

/**
 * Sets onboarding to completed
 * @param {FetchConfig} config
 */
export function finishOnboarding(config) {
  return http.post('/my/onboarding', config)
}

/**
 * @param {string} token - Password reset token
 * @param {string} password
 * @param {string} passwordConfirmation
 * @param {FetchConfig} config
 */
export function updatePassword(token, password, passwordConfirmation, config) {
  if (R.pathEq(['version'], 2, config)) {
    return updatePasswordV2(token, password, passwordConfirmation, config)
  } else {
    return updatePasswordV1(token, password, passwordConfirmation, config)
  }
}

/**
 * @param {string} token - Password reset token
 * @param {string} password
 * @param {string} passwordConfirmation
 * @param {FetchConfig} config
 */
export function updatePasswordV1(
  token,
  password,
  passwordConfirmation,
  config
) {
  const params = {
    body: {
      token,
      password,
      passwordConfirmation,
    },
  }

  const requestParams = R.merge({ params }, config)

  return http.put('/password', requestParams)
}

/**
 * @param {string} token - Password reset token
 * @param {string} password
 * @param {string} passwordConfirmation
 * @param {FetchConfig} config
 */
export function updatePasswordV2(
  token,
  password,
  passwordConfirmation,
  config
) {
  const params = {
    body: {
      password,
      passwordConfirmation,
    },
  }

  const requestParams = R.merge({ params }, config)

  return http.put(`/password_resets/${token}`, requestParams)
}
/**
 * @param {string} oldPassword
 * @param {string} newPassword
 * @param {string} newPasswordConfirmation
 * @param {FetchConfig} config
 */
export function changePassword(
  oldPassword,
  newPassword,
  newPasswordConfirmation,
  config
) {
  const params = {
    body: {
      player: {
        old_password: oldPassword,
        password: newPassword,
        password_confirmation: newPasswordConfirmation,
      },
    },
  }

  const requestParams = R.merge({ params }, config)

  return http.put('/my/passwords', requestParams)
}

/**
 * @param {FetchConfig} config
 */
export function fetchDepositInformation(config) {
  return http.get('/deposit/information', config)
}

/**
 * @param {FetchConfig} config
 */
export function fetchWithdrawInformation(config) {
  return http.get('/payment/withdrawable', config)
}

/**
 * @param {FetchConfig} config
 */
export function fetchNetDeposit(config) {
  return http.get('/receipts/net_deposits', config)
}

/**
 * Gets total deposits and withdraws for the last 12 months
 * @param {FetchConfig} config
 */
export function fetchTransactionTotals(config) {
  return http.get('/receipts/totals', config)
}

/**
 * @param {integer} page - Must be greater than 0
 * @param {FetchConfig} config
 */
export function fetchReceipts(page, config) {
  if (page !== parseInt(page, 10)) {
    throw new TypeError('page must be an integer')
  }
  if (page <= 0) {
    throw new TypeError('page must be greater than 0')
  }
  return http.get(`/receipts?page=${page}`, config)
}

/**
 * @param {number} amount
 * @param {string} preferredWelcomeOfferType ('none', 'money' or 'freespins')
 * @param {string} toCurrency
 * @param {FetchConfig} config
 */
export function convertDepositAmount(
  amount,
  preferredWelcomeOfferType,
  toCurrency,
  config
) {
  const params = {
    body: {
      amount,
      preferredWelcomeOfferType,
      toCurrency,
    },
  }

  const requestParams = R.merge({ params }, config)

  return http.post('/exchange/deposit', requestParams)
}

export function convertWithdrawAmount(amount, toCurrency, config) {
  const params = {
    body: {
      amount,
      toCurrency,
    },
  }

  const requestParams = R.merge({ params }, config)

  return http.post('/exchange/withdraw', requestParams)
}

/**
 * @param {number} amount
 * @param {FetchConfig} config
 */
export function convertBalanceAmount(amount, config) {
  const params = {
    body: {
      amount,
    },
  }

  const requestParams = R.merge({ params }, config)

  return http.post('/exchange/deposit_amount', requestParams)
}

/**
 * @param {FetchConfig} config
 */
export function requestSmsVerification(config) {
  return http.post('/my/phone_verification', config)
}
/**
 * @param {string} token
 * @param {FetchConfig} config
 */
export function verifyPhoneNumber(token, config) {
  return http.post(`/my/phone_verification/verify?token=${token}`, config)
}

/**
 * @param {FetchConfig} config
 */
export function requestEmailVerificationCode(config) {
  return http.post('/my/email_verification', config)
}
/**
 * @param {string} token
 * @param {FetchConfig} config
 */
export function verifyEmail(token, config) {
  const params = {
    body: {
      token,
    },
  }

  const requestParams = R.merge({ params }, config)

  return http.post('/my/email_verification/verify', requestParams)
}

// TODO: describe optional props if needed
/**
 * Request configuration object
 * @typedef {Object} AddressData
 * @prop {string} firstName
 * @prop {string} lastName
 * @prop {string} street
 * @prop {string} zip
 * @prop {string} city
 * @prop {string} country
 */

// TODO: describe optional props if needed
/**
 * @typedef {Object} UserData
 * @prop {string} username
 * @prop {string} email
 * @prop {string} password
 * @prop {string} passwordConfirmation
 * @prop {string} birthdate
 * @prop {string} firstName
 * @prop {string} lastName
 * @prop {string} street
 * @prop {string} zip
 * @prop {string} city
 * @prop {string} countryCode
 * @prop {string} gender
 * @prop {string} netrefererBTag
 */

/**
 * Get player profile including account balance and address
 * @param {FetchConfig} config
 */
export function fetchMe(config) {
  return http.get('/me', config)
}

/**
 * @param {UserData} userData
 * @param {FetchConfig} config
 */
export function updateMe(userData, config) {
  const requestParams = R.merge({ params: { body: userData } }, config)

  return http.put('/me', requestParams)
}

/**
 * @param {string} countryCallingCode
 * @param {string} phoneNumber
 * @param {FetchConfig} config
 */
export function validatePhoneNumber(countryCallingCode, phone, config) {
  const params = {
    country_calling_code: countryCallingCode,
    phone,
  }

  return http.post(`/validations/phone${toQueryString(params)}`, config)
}

/**
 * @param {AddressData} addressData
 * @param {FetchConfig} config
 */
export function validateAddress(addressData, config) {
  const requestParams = R.merge({ params: { body: addressData } }, config)

  return http.post('/validations/address', requestParams)
}

/**
 * @param {UserData} userData
 * @param {FetchConfig} config
 */
export function registerPlayer(userData, config) {
  const requestParams = R.merge({ params: { body: userData } }, config)

  return http.post('/register', requestParams)
}

/**
 * @param {FetchConfig} config
 */
export function lockPlayer(config) {
  return http.post('/my/iovation_verification_failures/', config)
}

/**
 * Validate uniqueness of email
 * @param {string} email
 * @param {FetchConfig} config
 */
export function validateEmail(email, config) {
  const params = {
    body: {
      attribute: 'email',
      value: email,
    },
  }

  const requestParams = R.merge({ params }, config)

  return http.post('/validate/player', requestParams)
}

/**
 * Validate uniqueness of username
 * @param {string} username
 * @param {FetchConfig} config
 */
export function validateUsername(username, config) {
  const params = {
    body: {
      attribute: 'username',
      value: username,
    },
  }

  const requestParams = R.merge({ params }, config)

  return http.post('/validate/player', requestParams)
}

/**
 * @param {string} countryCode
 * @param {FetchConfig} config
 */
export function fetchPromotions(countryCode, config) {
  return http.get(
    `/promotions${toQueryString({ country_code: countryCode })}`,
    config
  )
}

/**
 * @param {boolean} real - Return only recently played games
 * @param {FetchConfig} config
 */

export function fetchQuickGames(real, config) {
  return http.get(`/games/quick?real=${real}`, config)
}

/**
 * @param {string} username
 * @param {string} password
 * @param {string} seonSessionId - fingerprint value generated by seon
 * @param {string} clientType - either '' or 'mobile-browser'
 * @param {FetchConfig} config
 */
export function login(username, password, clientType, config, seonSessionId) {
  const params = {
    body: {
      login: username,
      password,
      clientType,
      seonSessionId: seonSessionId,
    },
  }

  const requestParams = R.merge({ params }, config)

  return http.post('/session', requestParams)
}

/**
 * @param {FetchConfig} config
 */
export function logout(config) {
  return http.delete('/session', config)
}

/**
 * Get current session
 * @param {FetchConfig} config
 */
export function fetchSession(config) {
  return http.get('/session', config)
}

/**
 * Get current session statistics
 * @param {FetchConfig} config
 */
export function fetchSessionStats(config) {
  return http.get('/my/session_stats', config)
}

/**
 * @param {string} email
 * @param {FetchConfig} config
 */
export function resetPassword(email, config) {
  const params = { body: { handle: email } }

  const requestParams = R.merge({ params }, config)

  if (R.pathEq(['version'], 2, config)) {
    return http.post('/password_resets', requestParams)
  }

  return http.post('/password', requestParams)
}

/**
 * @param {Object} data
 * @param {string} data.employment - Listing type of employment.
 * @param {string} data.occupation - Listing user occupation.
 * @param {string} data.salary - Listing user salary level.
 * @param {Array} data.sourcesOfIncome - Listing sources of income.
 * @param {string} data.nationality - Listing nationalities.
 * @param {string} data.placeOfBirth - Listing place of birthg.
 * @param {string} data.idNumber - Input of user ID number.
 * @param {string} data.anticipatedYearlyDeposits - Listing user anticpated yearly deposit level.
 * @param {Array} data.sourcesOfFunds - Listing sources of funds.
 * @param {Array} data.fundsCountryOrigin - Listing countries where funds come from.
 * @param {FetchConfig} config
 */
export function submitSourceOfFunds(data, config) {
  const params = {
    body: {
      employment: data.employment,
      occupation: data.occupation,
      salary: data.salary,
      sources_of_income: data.sourcesOfIncome,
      nationality: data.nationality,
      placeOfBirth: data.placeOfBirth,
      idNumber: data.idNumber,
      anticipatedYearlyDeposits: data.anticipatedYearlyDeposits,
      sourcesOfFunds: data.sourcesOfFunds,
      fundsCountryOrigin: data.fundsCountryOrigin,
    },
  }

  const requestParams = R.merge({ params }, config)

  return http.post('/source_of_funds', requestParams)
}

/**
 * @param {FetchConfig} config
 */
export function fetchStoreItems(config) {
  return http.get('/store_items', config)
}

/**
 * @param {FetchConfig} config
 */
export function fetchRealMoneyStoreItems(config) {
  return http.get('/store_items?sold_for_real_money=true', config)
}

/**
 * @param {(number|Object)} data
 * @param {number} data.id
 * @param {boolean} [data.blitz] - item should be blitz
 * @param {string} [data.stakeIndex] - chosen stake index in the array of stakes
 * @param {string} [data.transactionId] - transaction ID
 * @param {FetchConfig} config
 */
export function buyItem(data, config) {
  if (data && (data.blitz || !R.isNil(data.stakeIndex))) {
    const params = {
      body: {
        blitz: data.blitz,
        stakeIndex: data.stakeIndex,
        transactionId: data.transactionId,
      },
    }
    const requestParams = R.merge({ params }, config)

    return http.post(`/store_items/${data.id}/buy`, requestParams)
  }

  return http.post(`/store_items/${data}/buy`, config)
}

/**
 * @param {FetchConfig} config
 */
export function markStoreAsSeen(config) {
  return http.delete('/my/menu_items/store', config)
}

/**
 * @param {FetchConfig} config
 */
export function acceptPrivacyPolicy(config) {
  return http.put('/accept_tc', config)
}

/**
 * @param {FetchConfig} config
 */
export function acceptBonusFunds(config) {
  return http.put('/my/deposit_offer_prompt', config)
}

/**
 * @param {FetchConfig} config
 */
export function declineBonusFunds(config) {
  const params = {
    body: {
      declined_bonus: true,
    },
  }

  const requestParams = R.merge({ params }, config)
  return http.put('/my/deposit_offer_prompt', requestParams)
}

/**
 * @param {string} key - unique identifier for world
 * @param {FetchConfig} config
 */
export function fetchWorldRegions(key, config = {}) {
  /* istanbul ignore next */
  const normalizedSuccess = res => {
    const regionsSchema = new schema.Entity(
      'regions',
      {},
      { idAttribute: 'key' }
    )
    const normalizedResult = normalize(res.value.result, [regionsSchema])
    const response = R.assocPath(['value', 'result'], normalizedResult, res)

    return config.success(response)
  }

  return http.get(
    `/worlds/${key}/regions`,
    R.when(
      R.propEq('normalize', true),
      R.assoc('success', normalizedSuccess),
      config
    )
  )
}

/**
 * @param {FetchConfig} config
 */
export function fetchWorlds(config = {}) {
  /* istanbul ignore next */
  const normalizedSuccess = res => {
    const worldsSchema = new schema.Entity('worlds', {}, { idAttribute: 'key' })
    const normalizedResult = normalize(res.value.result, [worldsSchema])
    const response = R.assocPath(['value', 'result'], normalizedResult, res)

    return config.success(response)
  }

  return http.get(
    '/worlds',
    R.when(
      R.propEq('normalize', true),
      R.assoc('success', normalizedSuccess),
      config
    )
  )
}

/**
 * @param {FetchConfig} config
 */
export function finishWorld(config) {
  return http.post('/finish_world', config)
}

/**
 * @param {number} amount
 * @param {FetchConfig} config
 */
export function processWithdrawals(amount, config) {
  const params = {
    body: {
      amount,
    },
  }

  const requestParams = R.merge({ params }, config)

  return http.post('/withdrawals', requestParams)
}

/* istanbul ignore next */
function injectLinks(response) {
  const links = {
    mga: ['curacao'],
    sga: ['limits', 'timeout', 'selftest'],
    ukgc: ['curacao'],
  }

  return R.assocPath(
    ['value', 'links'],
    links[response.value.licenseId],
    response
  )
}

/**
 * Get jurisdictions
 * @param {FetchConfig} config
 */
export function fetchJurisdiction(config) {
  const noop = R.always(undefined)
  const success = R.pathOr(noop, ['success'], config)
  return http.get(
    '/jurisdiction',
    R.assoc(
      'success',
      R.pipe(
        injectLinks,
        success
      ),
      config
    )
  )
}

export function fetchLocks(config) {
  return http.get('/my/locks', config)
}

/**
 * @param {FetchConfig} config
 */
export function acceptTermsAndConditions(config) {
  return http.put('/terms_and_conditions', config)
}

/**
 * @param {Object} options Options for the post body
 * @param {number} options.amountCents Price amount in cents
 * @param {array} options.drawingDays Drawing days: ex ["WE","SU"]
 * @param {number} options.duration How many many weeks the purchase should be repeated
 * @param {array} options.firstDrawIds Draw id/s of the draws that should be purchased (Max one week from now, draws for week 2 or 3 will be handled in the backend in case of duration 2 or 3)
 * @param {string} options.firstDrawId Draw id of the ones that should be purchased (old alternative way to firstDrawIds)
 * @param {number} options.lines How many lines that should be purchased
 * @param {string} options.productId Which product the ticket/s should be purchased from
 * @param {FetchConfig} config
 */
export function purchaseLottoTicket(options, config) {
  const params = {
    body: {
      amountCents: options.amountCents,
      drawingDays: options.drawingDays,
      duration: options.duration,
      firstDrawIds: options.firstDrawId
        ? [options.firstDrawId]
        : options.firstDrawIds,
      lines: options.lines,
      lottery: options.productId,
    },
  }
  return http.post('/lottoland/lottery_tickets', R.merge({ params }, config))
}

/**
 * @param {string} currency
 * @param {FetchConfig} config
 */
export function fetchLottoPrices(currency, config) {
  const queryString = toQueryString({
    currency,
  })
  return http.get(`/lottoland/lottery_tickets/prices${queryString}`, config)
}

/**
 * @param {Object} options Options for the post body
 * @param {number} options.id Id of the lottery bundle to be purchased
 * @param {FetchConfig} config
 */
export function purchaseLottoBundle(options, config) {
  const params = {
    body: {
      id: options.id,
    },
  }
  return http.post('/lottoland/lottery_bags', R.merge({ params }, config))
}

/**
 * @param {FetchConfig} config
 */
export function fetchLottoBundles(config) {
  return http.get(`/lottoland/lottery_bags`, config)
}

/**
 * Send SMS notifications to the given number (if the player wins on any ticket related to the transactionId).
 * @param {object} options Transaction id and mobile phone number
 * @param {string} options.transaction_id The id of the purchase transaction (can point to one or several tickets)
 * @param {string} options.phone_number The mobile phone number to which as SMS will be sent
 */
export function requestLottoWinSmsNotifications(options, config) {
  const params = {
    body: {
      transaction_id: options.transactionId,
      phone_number: options.phoneNumber,
    },
  }
  return http.post(
    '/lottoland/phone_notifications',
    R.merge({ params }, config)
  )
}

/**
 * Fetch players custom games
 * @param {FetchConfig} config
 */
export function fetchPlayerCustomGames(config) {
  return http.get('/my/picked_games', config)
}

/**
 * Set players custom games
 * @param {Array} gameIds array of game ids
 * @param {FetchConfig} config
 */
export function updatePlayerCustomGames(gameIds, config) {
  const params = {
    body: {
      picks: gameIds,
    },
  }

  return http.put('/my/picked_games', R.merge({ params }, config))
}

/**
 * Fetch metadata for a particular URL
 * @param {object} params url
 * @param {string} params.url The URL for which the data is requested
 * @param {FetchConfig} config
 */
export function fetchSeoDescription(params, config) {
  return http.get(`/seo_descriptions/?url=${params.url}`, config)
}

/**
 * @param {FetchConfig} config
 */
export function claimBetBack(config) {
  return http.put('/my/betback', config)
}

/**
 * Mark all current user's notifications as seen
 * @param {FetchConfig} config
 */
export function markNotificationsAsSeen(config) {
  return http.put('/mark_notifications_as_seen', config)
}

/**
 * Get affiliate data
 * @param {Object} params
 * @param {string} params.btag NetRefer BTag
 * @param {FetchConfig} config
 */
export function fetchAffiliateData(params, config) {
  const queryString = toQueryString({
    btag: params.btag,
  })

  return http.get(`/affiliates${queryString}`, config)
}

/**
 * Request sendout of sms with verification-code to supplied number
 * @param {Object} data
 * @param {string} data.countryCallingCode Country calling code Ex. '+46'
 * @param {string} data.phone Phone number without CCC prefix Ex. '0707112233'
 * @param {string} data.language Language to receive sms in, Ex. 'en'
 * @param {FetchConfig} config
 */
export function requestPhoneVerificationCode(data, config) {
  const params = {
    body: {
      country_calling_code: data.countryCallingCode,
      phone: data.phone,
      language: data.language,
    },
  }

  return http.post('/phone_verification', R.merge({ params }, config))
}

/**
 * Dismiss high deposit limit prompt
 * @param {FetchConfig} config
 */
export function dismissHighDepositLimitPrompt(config) {
  return http.post('/my/high_deposit_limit_prompt', config)
}

/**
 * Fetches Shufti Pro generated iframe URL
 * @param {Object} options
 * @param {string} options.redirectUrl
 * @param {('identity'|'address'|'additional')} options.kind
 * @param {FetchConfig} config
 */
export function fetchShuftiProIframeUrl(options, config) {
  const params = {
    body: {
      redirect_url: options.redirectUrl,
      kind: options.kind,
    },
  }

  return http.post('/my/shufti_documents', R.merge({ params }, config))
}

/**
 * Fetches Shufti Pro documents for player
 * @param {FetchConfig} config
 */
export function fetchShuftiProDocuments(config) {
  return http.get('/my/shufti_documents', config)
}

/**
 * @param {FetchConfig} config
 */

export function fetchGameTransactionsTotals(config) {
  return http.get(`/my/game_transactions/totals`, config)
}

/**
 * @param {FetchConfig} config
 */
export function dismissCustomerDueDiligencePrompt(config) {
  return http.post('/my/dismiss_cdd', config)
}
